<template>
  <footer class="footer graphite-bg">
    <div class="container">
      <div class="footer__top">
        <div class="row row-gap-20">
          <div class="col-lg-2 col-md-4">
            <div class="mb-30">
              <router-link :to="$localePath({ name: 'Home' })">
                <img :src="require('@/assets/img/logo-white.svg')" alt="" />
              </router-link>
            </div>
            <p class="mb-10">
              <a href="mailto:support@ibxtrade.com" class="link-social">
                E-mail
              </a>
            </p>
            <p>
              <a href="tg://resolve?domain=ibxtradesupport" class="link-social">
                Telegram
              </a>
            </p>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="mb-20">
              <router-link
                :to="$localePath({ name: 'Markets' })"
                class="footer__title"
              >
                {{ $t("menu.markets") }}
              </router-link>
            </div>

            <ul class="footer__menu">
              <li>
                <router-link
                  :to="$localePath({ name: 'Forex' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.forex") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Indices' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.indices") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Stock' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.stock") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Commodities' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.commodities") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Currency' })"
                  class="menu__item"
                >
                  {{ $t("subMenu.currency") }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-4 col-6">
            <div class="footer__title mb-20">{{ $t("company") }}</div>
            <ul class="footer__menu mb-20">
              <li>
                <router-link
                  :to="$localePath({ name: 'About' })"
                  class="menu__item"
                >
                  {{ $t("menu.about") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Safety' })"
                  class="menu__item"
                >
                  {{ $t("menu.safe") }}
                </router-link>
              </li>
            </ul>
            <div class="mb-20">
              <router-link
                :to="$localePath({ name: 'Platform' })"
                class="footer__title"
              >
                {{ $t("menu.platform") }}
              </router-link>
            </div>

            <router-link
              :to="$localePath({ name: 'Contacts' })"
              class="footer__title"
            >
              {{ $t("menu.contacts") }}
            </router-link>
          </div>
          <div class="col-lg-6">
            <p class="footer__text">{{ $t("footerText") }}</p>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="row row-gap-20 flex-lg-row-reverse">
          <div class="col-lg-6">
            <div class="link-block">
              <router-link
                :to="$localePath({ name: 'Privacy' })"
                class="footer__link"
              >
                {{ $t("privacy") }}
              </router-link>
            </div>
          </div>
          <div class="col-lg-6">
            <p>IBX Trade © {{ getYear }}, all rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },

  i18n: {
    messages: {
      en: {
        footerText:
          "Trading financial assets such as stocks, indices, commodities, cryptocurrency and trading pairs offers the opportunity to make high profits, but carries the risk of loss. It is highly recommended that you research the specifics of each instrument before you start trading. Make sure you are fully aware of all the risks associated with this activity. The contents of this website do not constitute personal investment advice.Remember that past performance is no guarantee of future success and trading always involves certain risks. We strongly recommend that you allocate your investment portfolio to minimise risk and pay attention to the current economic situation and geopolitical factors that may affect the financial markets.",
      },
      ru: {
        footerText:
          "Торговля финансовыми активами, такими как акции, индексы, сырьевые товары, криптовалюта и торговые пары, дает возможность получить высокую прибыль, но несет в себе риск потерь. Прежде чем приступить к торговле, настоятельно рекомендуется изучить особенности каждого инструмента. Убедитесь, что вы полностью осознаете все риски, связанные с этой деятельностью. Содержание данного веб-сайта не является персональной инвестиционной рекомендацией. Помните, что прошлые результаты не гарантируют будущего успеха, а торговля всегда связана с определенными рисками. Мы настоятельно рекомендуем вам распределять свой инвестиционный портфель таким образом, чтобы минимизировать риски, и обращать внимание на текущую экономическую ситуацию и геополитические факторы, которые могут повлиять на финансовые рынки.",
      },
      fr: {
        footerText:
          "La négociation d'actifs financiers tels que les actions, les indices, les matières premières, les crypto-monnaies et les paires de trading offre la possibilité de réaliser des profits élevés, mais comporte un risque de perte. Il est fortement recommandé de se renseigner sur les spécificités de chaque instrument avant de commencer à négocier. Assurez-vous d'être pleinement conscient de tous les risques associés à cette activité. Le contenu de ce site web ne constitue pas un conseil d'investissement personnel.N'oubliez pas que les performances passées ne sont pas une garantie de succès futur et que le trading comporte toujours certains risques. Nous vous recommandons vivement de répartir votre portefeuille d'investissement de manière à minimiser les risques et de prêter attention à la situation économique actuelle et aux facteurs géopolitiques susceptibles d'affecter les marchés financiers.",
      },
      de: {
        footerText:
          "Der Handel mit Finanzanlagen wie Aktien, Indizes, Rohstoffen, Kryptowährungen und Handelspaaren bietet die Möglichkeit, hohe Gewinne zu erzielen, birgt jedoch auch das Risiko von Verlusten. Es wird dringend empfohlen, dass Sie sich über die Besonderheiten jedes Instruments informieren, bevor Sie mit dem Handel beginnen. Stellen Sie sicher, dass Sie sich aller mit dieser Aktivität verbundenen Risiken voll bewusst sind. Die Inhalte dieser Website stellen keine persönliche Anlageberatung dar. Denken Sie daran, dass vergangene Wertentwicklungen keine Garantie für zukünftigen Erfolg sind und der Handel immer mit bestimmten Risiken verbunden ist. Wir empfehlen Ihnen dringend, Ihr Anlageportfolio so zu strukturieren, dass das Risiko minimiert wird, und auf die aktuelle Wirtschaftslage und geopolitische Faktoren zu achten, die die Finanzmärkte beeinflussen können.",
      },
    },
  },
};
</script>

<style scoped lang="scss">
.footer__top {
  padding: 80px 0 60px;
}
.footer__bottom {
  font-family: "sansationregular";
  border-top: 1px solid $dark;
  padding: 40px 0;
  font-size: 14px;
  color: $gray2;

  .link-block {
    display: flex;
    align-items: center;
    gap: 20px 60px;
  }
}
.footer__link {
  color: $gray2;
  text-decoration: underline;

  &:hover {
    color: $white;
    text-decoration: none;
  }
}
.footer__text {
  font-size: 14px;
  color: $gray2;
}
.footer__title {
  font-family: "sansationbold";
  color: $primary-color;
  font-size: 18px;
}
.footer__menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: "sansationregular";

  .menu__item {
    color: $white;

    &:hover {
      color: $primary-color;
    }
  }
}
.link-social {
  font-family: "sansationregular";
  color: $white;
}

@include media-breakpoint-down(lg) {
  .footer__top {
    padding: 60px 0 40px;
  }
}
</style>
